import React, {FunctionComponent} from 'react';
import {Box, makeStyles, Theme, Typography, Select, MenuItem} from '@material-ui/core';


const useStyles = makeStyles<Theme>(theme => ({}));

interface ColumnTypeProps {
    variants: any[];
    value: number;
    handleChange: (value: number) => void;
}

const ColumnType: FunctionComponent<ColumnTypeProps> = ({value, variants, handleChange}: ColumnTypeProps) => {
    const classes = useStyles();

    return (
        <>
            <Select
                value={value}
                displayEmpty
                onChange={(event) => handleChange(parseInt(event.target.value as string))}>
                <MenuItem value="0">
                </MenuItem>
                {variants.map(m => <MenuItem value={m.value} key={m.value}>{m.label}</MenuItem>)}
            </Select>
        </>
    );
};

export default ColumnType;
