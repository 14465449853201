// import {CurrentProjectActions, CurrentProjectState, ProjectsAction} from "../actions/projectActions";
// import {currentProjectReducer, projectsReducer, ProjectsState} from "../reducers/projectReducers";
// import {CurrentProjectActions, currentProjectReducer, CurrentProjectState} from "../reducers/currentProjectReducer";
// import { ProjectCompanysAction} from "../actions/projectCompanyActions";
// import {projectCompanyReducer, ProjectCompanyState} from "../reducers/projectCompanyReducer";
// import {PersonsAction} from "../actions/personActions";
// import {personsReducer, PersonsState} from "../reducers/personReducers";
// import {cardsReducer, CardsState} from "../reducers/cardReducers";
// import {CardsAction} from "../actions/cardActions";
// import {userReducer, UserState} from "../reducers/userReducers";
// import {UsersActions} from "../actions/userActions";

export interface ApplicationState {
    // projects: ProjectsState | undefined;
    // currentProject: CurrentProjectState | undefined;
    // projectCompany: ProjectCompanyState | undefined;
    // persons: PersonsState | undefined;
    // cards: CardsState | undefined;
    // users: UserState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    // projects: projectsReducer,
    // currentProject: currentProjectReducer,
    // projectCompany: projectCompanyReducer,
    // persons: personsReducer,
    // cards: cardsReducer,
    // users: userReducer,
};

export type RootActions = null;
// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
