import React, {FunctionComponent, useState} from 'react';
import {Box, makeStyles, TableHead, Theme, Typography, Table, TableRow, TableBody, TableCell} from '@material-ui/core';
import ItemRow from './ItemRow';
import ChangeResultDialog from './ChangeResultDialog';
import axios from '../../api';


const useStyles = makeStyles<Theme>(theme => ({
    root: {
        maxWidth: '100%',
        overflow: 'auto'
    }
}));

interface ParseTableProps {
    original: any[];
    processed?: any[];
    onItemClick: (itemOriginal: any, itemProcessed: any) => void;
}

const ParseTable: FunctionComponent<ParseTableProps> = ({original, processed, onItemClick}: ParseTableProps) => {
    console.log('ParseTable');
    const classes = useStyles();

    return (
        <Box className={classes.root}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Шифр</TableCell>
                        <TableCell>№ поз</TableCell>
                        <TableCell>Наименование в Деф-акте</TableCell>
                        <TableCell>Единицы</TableCell>
                        <TableCell>Колич. АВК</TableCell>

                        <TableCell>&nbsp;</TableCell>

                        <TableCell>№ поз</TableCell>
                        <TableCell>Шифр</TableCell>
                        <TableCell>Наименование</TableCell>
                        <TableCell>Колл. совпадений</TableCell>
                        <TableCell>Единицы</TableCell>
                        <TableCell>Колич. АВК</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {original.map((m, index) => <ItemRow onDoubleClick={() => {
                        onItemClick(m, processed ? processed[index] : null);
                        // setResultItem({original: m, processed: processed ? processed[index] : null});
                        // setResultChangeDialogState(true);
                    }} key={'itemrow_index' + index} itemOriginal={m} itemProcessed={processed ? processed[index] : null}/>)}
                </TableBody>
            </Table>
        </Box>
    );
};

export default ParseTable;
