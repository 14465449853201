import React, {ChangeEventHandler, FunctionComponent, useState} from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles, TextField, Theme, Typography} from '@material-ui/core';
// import axios from 'axios';
import axios from '../../api';
import {useHistory} from 'react-router';


const useStyles = makeStyles<Theme>(theme => ({
    customFileUpload: {
        // border: 1px solid #ccc;
        // display: inline-block;
        // padding: 6px 12px;
        // cursor: pointer;
        // width: '93.91px',
        // height: '37px',
        border: '1px solid #000000',
        display: 'block',
        fontSize: '14px',
        padding: '10px 10px',
        cursor: 'pointer'
    },
    inputFile: {
        display: 'none'
    }
}));

interface UploadFileProps {

}

const UploadFile: FunctionComponent<UploadFileProps> = (props: UploadFileProps) => {
    const classes = useStyles();
    const history = useHistory();

    const [projectName, setProjectName] = useState('');

    const [selectedFile, setSelectedFile] = useState(null);

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSend = () => {
        onFileUpload(selectedFile, projectName);
    }

    const changeProjectName = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setProjectName(value);
    }


    const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelectedFile(event.target.files[0]);
        handleClickOpen();
        event.target.value = null;
    }

    const onFileUpload = async (file: any, name: string) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('projectName', name || file.name);

        const response = await axios.post('api/file/upload', formData);
        const data: any = response.data;
        const fileId = data.fileId
        history.push('/process/' + fileId);
    }
    return (
        <>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Загрузка нового проекта</DialogTitle>
                <DialogContent>
                    <TextField
                        onChange={changeProjectName}
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Название проекта"
                        type="text"
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Отмена
                    </Button>
                    <Button onClick={handleSend} color="primary">
                        Отправить
                    </Button>
                </DialogActions>
            </Dialog>
            <label htmlFor="file-upload" className={classes.customFileUpload}>Открыть</label>
            <input id="file-upload" type="file" className={classes.inputFile} onChange={onFileChange} accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"/>
            {/*<input type="file" onChange={onFileChange} accept=""/>*/}
            {/*<Button onClick={()=>setOpen(true)}>send</Button>*/}
        </>
    );
};

export default UploadFile;
