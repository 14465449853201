import * as React from 'react';
import * as ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {ConnectedRouter} from 'connected-react-router';
import {createBrowserHistory} from 'history';
import configureStore from './store/configureStore';
// import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import App from './App';
import {MuiThemeProvider} from '@material-ui/core';
import { theme } from './styles/theme';
import 'typeface-roboto';


// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({basename: baseUrl});
const store = configureStore(history);

ReactDOM.render(
    <Provider store={store}>
        <MuiThemeProvider theme={theme}>
        <ConnectedRouter history={history}>
            <BrowserRouter forceRefresh={false}>
                <SnackbarProvider maxSnack={3}>
                    <App/>
                </SnackbarProvider>
            </BrowserRouter>
        </ConnectedRouter>
        </MuiThemeProvider>
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
