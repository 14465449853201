import axios, {AxiosInstance, AxiosRequestConfig} from 'axios';
import _ from "lodash";


// Add a response interceptor
axios.interceptors.response.use(function (response) {
    // debugger;
    // Do something with response data
    let newData: any = {};
    if (response && response.data && response.data.error) {
        return Promise.reject(response.data.error);
    } else {
        response.data = response.data.data;
    }
    // response.data = [];
    return response;
}, function (error) {
    debugger;
    // Do something with response error
    return Promise.reject(error);
});

const orgCreate = axios.create;
axios.create = function (config?: AxiosRequestConfig): AxiosInstance {
    const result = orgCreate.call(this, config);
    _.forEach((axios.interceptors.request as any).handlers, (handler) => {
        result.interceptors.request.use(handler.fulfilled, handler.rejected);
    });
    _.forEach((axios.interceptors.response as any).handlers, (handler) => {
        result.interceptors.response.use(handler.fulfilled, handler.rejected);
    });
    return result;
};
export default axios.create({
    baseURL: process.env.REACT_APP_BACK_URL,
    // withCredentials: true,
})
