import React, {FunctionComponent} from 'react';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import UploadFile from '../components/UploadFile';


const useStyles = makeStyles<Theme>(theme => ({
    root: {
        backgroundColor: '#E0E0E0',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px'
    }
}));

interface StartPageProps {

}

const StartPage: FunctionComponent<StartPageProps> = (props: StartPageProps) => {
  const classes = useStyles();

  return (
    <>
        <Box className={classes.root}>
            <UploadFile/>

        </Box>
    </>
  );
};

export default StartPage;
