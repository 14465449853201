import React, {FunctionComponent} from 'react';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import {AvkResultItem} from '../../types';
import cx from 'classnames';


const useStyles = makeStyles<Theme>(theme => ({
    row: {
        cursor: 'pointer',
        padding: '4px',
        '&:hover': {
            backgroundColor: 'lightgray'
        },
    },
    active: {
        backgroundColor: 'lightgray'
    },
    noClick: {
        pointerEvents: 'none'
    },
    code: {
        minWidth: '100px',
        color: '#4F4F4F',
        fontSize: '14px'
    },
    name: {
        minWidth: '100px',
        color: '#333333',
        fontSize: '14px'
    }
}));

interface ResultVariantItemProps {
    item: AvkResultItem;
    onClick?: () => void;
    active: boolean;
}

const ResultVariantItem: FunctionComponent<ResultVariantItemProps> = ({item, onClick, active}: ResultVariantItemProps) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.row, {
            [classes.noClick]: !onClick,
            [classes.active]: active
        })} display={'flex'} alignItems={'center'} mb={1} onClick={() => onClick ? onClick() : null}>
            <Box className={classes.code}>{item.code}</Box>
            <Box className={classes.name}>{item.ukName}</Box>
        </Box>
    );
};

export default ResultVariantItem;
