import React, {FunctionComponent} from 'react';
import {Box, makeStyles, Radio, TableCell, TableRow, Theme, Typography} from '@material-ui/core';


const useStyles = makeStyles<Theme>(theme => ({}));

interface ItemRowProps {
    item: any;
    index: number;
    selectedFirstRowData: number;
    handleChange:(index: number)=>void
}

const ItemRow: FunctionComponent<ItemRowProps> = ({item, index, selectedFirstRowData, handleChange}: ItemRowProps) => {
    const classes = useStyles();

    return (
        <TableRow>
            <TableCell>
                <Radio
                    checked={selectedFirstRowData === index}
                    onChange={()=>handleChange(index)}
                    value={index}
                    name="radio-button-demo"
                    // inputProps={{'aria-label': 'A'}}
                />
            </TableCell>
            <TableCell>{index + 1}</TableCell>
            {Object.keys(item).map(m => <TableCell key={"c-"+m}>{item[m]}</TableCell>)}
        </TableRow>
    );
};

export default ItemRow;
