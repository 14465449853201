import React, {FunctionComponent} from 'react';
import {Box, makeStyles, TableCell, TableRow, Theme, Typography, useMediaQuery} from '@material-ui/core';
import cx from 'classnames';
import {Visibility} from '@material-ui/icons';


const useStyles = makeStyles<Theme>(theme => ({
    root: {
        // backgroundColor: 'lightgray !important'
        '& td': {
            borderLeft: '1px solid #000000',
            borderRight: '1px solid #000000',
        }
    },
    match: {
        '& $result': {
            backgroundColor: '#90ee9052'
        }
    },
    selected: {
        '& $result': {
            backgroundColor: '#90ee9052'
        }
    },
    failed: {},
    result: {
        // backgroundColor: 'lightgray !important'
        visibility: 'hidden'
    },
    processed: {
      '& $result': {
          visibility: 'visible'
      }
    },
    noData: {
        '& td': {
            color: '#bababa'
        }
    },
    emptyCell: {
        backgroundColor: 'transparent !important'
    },
    oddRow: {
        '& td': {
            backgroundColor: '#F3EFEF'
        }
    }
}));

interface ItemRowProps {
    itemOriginal: any;
    itemProcessed?: any;

    onDoubleClick: () => void;
}

const ItemRow: FunctionComponent<ItemRowProps> = ({itemOriginal, itemProcessed, onDoubleClick}: ItemRowProps) => {
    const classes = useStyles();

    const variantsCount = itemProcessed && itemProcessed.result ? itemProcessed.result.length : 0;

    const getResultValue = (field: string) => {
        return itemProcessed ?
            itemProcessed.selected ? itemProcessed.selected[field]
                : itemProcessed.result && itemProcessed.result.length ? itemProcessed.result[0][field] : null
            : null
    }

    const resultCode = getResultValue('code');
    const resultName = getResultValue('ukName');
    const resultUnit = getResultValue('ukUnit');

    const isTouchDevice = (() => {
        return (('ontouchstart' in window) ||
            (navigator.maxTouchPoints > 0) ||
            (navigator.msMaxTouchPoints > 0));
    })();
    const props = !isTouchDevice ? {
        onDoubleClick: () => itemOriginal && itemOriginal.isData ? onDoubleClick() : null,
    } : {onClick: () => itemOriginal && itemOriginal.isData ? onDoubleClick() : null}

    return (
        <TableRow className={cx(classes.root, {
            // @todo: maybe move this into makeStyles
            [classes.processed]: !!itemProcessed,
            [classes.noData]: itemOriginal && !itemOriginal.isData,
            [classes.match]: itemProcessed && itemProcessed.match,
            [classes.selected]: itemProcessed && !itemProcessed.match && !!itemProcessed.selected,
            [classes.failed]: itemProcessed && !itemProcessed.match && !itemProcessed.result.length,
            [classes.oddRow]: itemOriginal.rowIndex % 2 === 0,
        })} {...props}>
            <TableCell>
                {itemOriginal.code}
            </TableCell>
            <TableCell>
                {itemOriginal.num}
            </TableCell>
            <TableCell>
                {itemOriginal.name}
            </TableCell>
            <TableCell>
                {itemOriginal.unit}
            </TableCell>
            <TableCell>
                {itemOriginal.count}
            </TableCell>

            <TableCell className={classes.emptyCell}>&nbsp;</TableCell>


            <TableCell className={classes.result}>{itemOriginal.num}</TableCell>

            <TableCell className={classes.result}>
                {resultCode}
                {/*{itemProcessed && !itemProcessed.match && !!itemProcessed.selected && <Visibility/>}*/}
            </TableCell>
            <TableCell className={classes.result}>
                {resultName}
            </TableCell>
            <TableCell className={classes.result}>
                {itemOriginal && itemOriginal.isData ? variantsCount : null}
            </TableCell>

            <TableCell className={classes.result}>
                {resultUnit}
            </TableCell>
            <TableCell className={classes.result}>
                {itemOriginal.count}
            </TableCell>
        </TableRow>
    );
};

export default ItemRow;
