import {createTheme} from '@material-ui/core';

const theme = createTheme({
  palette: {
    primary: {
      main: '#8811FF',
    },
  },
  shape: {
    borderRadius: 4,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        //override the pseudo-classes
        '.Mui-disabled': { opacity: .5 }
      }
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
  },
});

export {theme};
