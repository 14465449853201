import React, {FunctionComponent, useEffect, useMemo, useState} from 'react';
import {Box, Button, makeStyles, Snackbar, Stepper, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography} from '@material-ui/core';
import ItemRow from './ItemRow';
import ColumnType from './ColumnType';
import axios from '../../api';
import {Done} from '@material-ui/icons';


const useStyles = makeStyles<Theme>(theme => ({}));

interface PrepareTableProps {
    data: any;
    onSave: (params: any, baseRow: number)=>void;
    saveTrigger: boolean;
}

const PrepareTable: FunctionComponent<PrepareTableProps> = ({data, onSave, saveTrigger}: PrepareTableProps) => {

    const classes = useStyles();

    const steps = [
        'Указать колонки', "Выбрать строку заголовка таблицы"
    ]

    const items:any[] = data.topcells;
    const sheetName = data.name;
    const columnTypes = data.columnTypes;

    const firstRow = items.length ? items[0] : {};

    const [selectedFirstRowData, setSelectedFirstRowData] = useState(-1);

    const handleChangeFirstRow = (index: number)=>{
        setSelectedFirstRowData(index);
    }

    const [columnTypeValues, setColumnTypeValues] = useState<any>({});

    const handleChangeColumnType = (key: string, value: number) =>{
        setColumnTypeValues({...columnTypeValues, [key]:value})
    }

    const checkColumnTypes = useMemo(() => [2,3,4,5].every((v:any)=>Object.values(columnTypeValues).includes(v)), [columnTypeValues]);

    useEffect(() => {
        (async () => {
            if (saveTrigger) {
                if(selectedFirstRowData === -1){
                    setShowError('Необходимо выбрать строку с заголовком')
                }else if(!checkColumnTypes){
                    setShowError('Необходимо выбрать тип каждой колонки')
                } else {
                    onSave(columnTypeValues, selectedFirstRowData)
                }
            }
        })();
    }, [saveTrigger]);

    const [showError, setShowError] = useState('');

    return (
        <Box>
            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={!!showError}
                onClose={()=>setShowError('')}
                message={showError}
            />
            <Typography variant={"h5"}>Подготовка к импорту:</Typography>
            <Box>
                <Typography variant={"subtitle1"}> - выберите тип колонок {checkColumnTypes && <Done color={"primary"}/>}</Typography>
                <Typography variant={"subtitle1"}> - выберите строку с заголовком {selectedFirstRowData>-1 && <Done color={"primary"}/>}</Typography>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Тип колонки</TableCell>
                        <TableCell/>
                        {Object.keys(firstRow).map(m=><TableCell key={"a-"+m}><ColumnType variants={columnTypes} value={columnTypeValues[m] || 0} handleChange={(value)=>(handleChangeColumnType(m, value))}/></TableCell>)}
                    </TableRow>
                    <TableRow>
                        <TableCell/>
                        <TableCell/>
                        {Object.keys(firstRow).map(m=><TableCell key={"b-"+m}>{m}</TableCell>)}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items.map((m, index) => <ItemRow selectedFirstRowData={selectedFirstRowData} item={m} key={"d-"+index} index={index} handleChange={handleChangeFirstRow}/>)}
                </TableBody>
            </Table>
        </Box>
    );
};

export default PrepareTable;
