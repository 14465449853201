import React, {FunctionComponent, useEffect, useState} from 'react';
import {
    Box,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogTitle,
    makeStyles,
    Theme,
    Typography,
    DialogActions,
    Button,
    Switch,
    TextField,
    useTheme,
    useMediaQuery
} from '@material-ui/core';
import ResultVariantItem from './ResultVariantItem';
import axios from '../../api';


const useStyles = makeStyles<Theme>(theme => ({
    dialogGitle: {
      padding: "16px 0 0 24px"
    },
    originalText: {
        fontSize: '14px',
        lineHeight: '16px',
        color: '#4F4F4F'
    },
    variantsContainer: {
        overflow: 'auto',
        maxHeight: '200px'
    },
    root: {
        width: '567px',
        maxWidth: '100vw',
        height: '577px',
        maxHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('xs')]: {
            height: '100vh',
        },
    },
    variantTitle: {
        margin: '18px 0'
    }
}));

interface ChangeResultDialogProps {
    item: any;
    onUpdateItem: (itemOriginal: any, itemResult: any, needSave: boolean) => void;
    openState: boolean;
    handleClose: () => void;
}

const ChangeResultDialog: FunctionComponent<ChangeResultDialogProps> = ({item, openState, handleClose, onUpdateItem}: ChangeResultDialogProps) => {
    const classes = useStyles();

    const [selected, setSelected] = useState(null);
    const [checked, setChecked] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');

    const [variants, setVariants] = useState([]);

    const initialVariants = item.processed ? item.processed.result : [];

    const handleChange = () => {
        setChecked(!checked);
    }

    const filterItem = async (query: string) => {
        const tryFilter = initialVariants.filter((f: any) =>
            f.code.toLowerCase().includes(query.toLowerCase())
            || f.ukName.toLowerCase().includes(query.toLowerCase()));
        if (tryFilter.length) {
            setVariants(tryFilter);
        } else {
            setVariants([]);
            const response = await axios.get(`/api/vocabulary/find?query=${query}`);
            if (response && response.data) {
                setVariants(response.data);
            }
        }
    }

    const changeSearch = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setSearchQuery(value);
        await filterItem(value);
    }

    useEffect(() => {
        (async () => {
            setChecked(false);
            setVariants(initialVariants);
            setSearchQuery('');
            if (item.processed && item.processed.selected) {
                setSelected(item.processed.selected);
            } else if (item.processed && item.processed.result && item.processed.result.length) {
                setSelected(item.processed.result[0]);
            } else {
                setSelected(null);
            }
        })();
    }, [item]);

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Dialog
            // fullWidth={fullWidth}
            maxWidth={'sm'}
            open={openState}
            onClose={handleClose}
            fullScreen={fullScreen}
        >
            <Box className={classes.root}>
                <DialogTitle className={classes.dialogGitle}>Заменить</DialogTitle>
                <DialogContent>
                    <Box mt={1} mb={1}>
                        <Typography>
                            Поисковый запрос:
                        </Typography>
                        <Box className={classes.originalText}>{item.original.name}</Box>
                    </Box>
                    <Box mt={1} mb={1}>
                        <Typography>
                            Выбранный вариант:
                        </Typography>
                        <Typography variant="h5">
                            {selected && <ResultVariantItem item={selected} active={false}/>}
                        </Typography>
                    </Box>
                    <Box>
                            <TextField id="standard-basic" label="Дополнительный поиск" onChange={changeSearch} value={searchQuery} fullWidth variant="outlined"/>
                        <Typography className={classes.variantTitle}>
                            Частичных совпадений ({variants.length})
                        </Typography>
                        <Box className={classes.variantsContainer}>
                            {variants.map((m: any) => <ResultVariantItem active={selected && selected.code === m.code} key={m.code} item={m} onClick={() => setSelected(m)}/>)}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отмена</Button>
                    {selected ?
                        <>
                            <Button onClick={() => onUpdateItem(item, selected, checked)}>Сохранить</Button>
                            <Switch
                                checked={checked}
                                onChange={handleChange}
                                inputProps={{'aria-label': 'controlled'}}
                            />
                        </>
                        : null
                    }

                </DialogActions>
            </Box>
        </Dialog>
    );
};

export default ChangeResultDialog;
