import React, {FunctionComponent, useEffect, useState} from 'react';
import {Box, Button, makeStyles, TextField, Theme, Typography} from '@material-ui/core';
import {RouteComponentProps, useHistory} from 'react-router';
import axios from '../api';
import cx from 'classnames';
// @ts-ignore
import OutsideClickHandler from 'react-outside-click-handler';


const useStyles = makeStyles<Theme>(theme => ({
    header: {
        backgroundColor: '#E0E0E0',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px'
    },
    baseItem: {
        margin: '10px 0',
        maxWidth: '200px',
        textAlign: 'center',
        cursor: 'pointer',
        '& p': {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        },
        '&:hover': {
            backgroundColor: '#E0E0E0',
        }
    },
    activeItem: {
        backgroundColor: '#E0E0E0',
    },
    filterInput: {
        marginLeft: '30px',
        marginBottom: '12px'
    }
}));

interface ProjectsProps extends RouteComponentProps {

}


const ProjectsPage: FunctionComponent<ProjectsProps> = (props: ProjectsProps) => {
    const classes = useStyles();

    const history = useHistory();


    const [projects, setProjects] = useState([]);
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [selectedFile, setSelectedFile] = useState('');

    const [filter, setFilter] = useState('');

    const changeFilterHandler = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setFilter(value);
        initFilter(value);
    }

    const initFilter = (filter: string) => {
        setFilteredProjects(projects.filter((f: any) => f.name && f.name.toLowerCase().includes(filter.toLowerCase())));
    }

    useEffect(() => {
        (async () => {
            const response = await axios.get(`/api/parser/documents`);
            if (response && response.data) {
                setProjects(response.data);
                setFilteredProjects(response.data);
            }
        })();
    }, []);

    const clickItemHandler = (item: any) => {
        setSelectedFile(item.key)
    }

    const openProject = (project: string)=>{
        history.push('/process/' + project);
    }

    const dblClickHandler = (item: any) => {
        openProject(item.key);
    }


    return (
        <>
            <Box className={classes.header}>
                <Button disableElevation disabled={!selectedFile} variant="outlined" onClick={() => {
                    openProject(selectedFile);
                }}>Обзор</Button>
                <TextField
                    className={classes.filterInput}
                    onChange={changeFilterHandler}
                    value={filter}
                    label="Фильтр"
                    type="text"
                    fullWidth
                />
            </Box>
            <OutsideClickHandler onOutsideClick={() => setTimeout(()=>setSelectedFile(null), 300)}>
                <Box display="flex" alignItems={'center'} flexWrap={'wrap'} className={classes.container}>
                    {filteredProjects.map((m: any) => <Box p={2} key={m.key} onDoubleClick={() => dblClickHandler(m)} onClick={() => clickItemHandler(m)} className={cx(classes.baseItem, {
                        [classes.activeItem]: m.key === selectedFile
                    })}>
                        <img src={require('../img/project_icon.png')}/>
                        <Typography>{m.name}</Typography>
                    </Box>)}
                </Box>
            </OutsideClickHandler>
        </>
    );
};

export default ProjectsPage;
