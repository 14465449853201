import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {connectRouter, routerMiddleware} from 'connected-react-router';
import {History} from 'history';
import {ApplicationState, reducers} from './';
// const authInterceptor = ({ dispatch }) => (next) => (action) => {
//     if (action.status === 401) {
//         dispatch(actions.removeJwt());
//     } else {
//         next(action);
//     }
// };
const crashReporter = (history: History) => (store: { getState: () => any; }) => (next: (arg0: any) => any) => (action: any) => {
    try {
        console.log(action);
        console.log(store.getState());
        console.log(history);
       /* if(action && action.type.indexOf('FAIL')>-1)
        {
            history.replace('/admin/login');
            return ;
        }*/
        // debugger;
        return next(action)
    } catch (err) {
        console.error('Caught an exception!', err)

        throw err
    }
};
export default function configureStore(history: History, initialState?: ApplicationState) {
    const middleware = [
        thunk,
        crashReporter(history),
        routerMiddleware(history)
    ];

    const rootReducer = combineReducers({
        ...reducers,
        router: connectRouter(history)
    });

    const enhancers = [];
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    if (windowIfDefined && windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
    }

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}
