import React, {FunctionComponent, useCallback, useEffect, useMemo, useState} from 'react';
import {Badge, Box, Button, CircularProgress, List, ListItem, ListItemIcon, ListItemText, makeStyles, TextField, Theme, Typography} from '@material-ui/core';
import {RouteComponentProps, RouteProps} from 'react-router';
import {BrowserRouterProps} from 'react-router-dom';
import axios from '../api';
import PrepareTable from '../components/PrepareTable';
import ParseTable from '../components/ParseTable';
import {Drafts, Inbox} from '@material-ui/icons';
import {debounce} from 'lodash';
import ChangeResultDialog from '../components/ParseTable/ChangeResultDialog';

// import debounce from 'lodash.';


const useStyles = makeStyles<Theme>(theme => ({
    header: {
        backgroundColor: '#E0E0E0',
        height: '69px',
        display: 'flex',
        alignItems: 'center',
        padding: '0 10px'
    },
    filterInput: {
        marginLeft: '30px',
        marginBottom: '12px'
    }
}));

interface ProcessPageProps extends RouteComponentProps<{ fileId: string }> {

}

const ProcessPage: FunctionComponent<ProcessPageProps> = ({location, match}: ProcessPageProps) => {
    const classes = useStyles();
    const fileId = match.params.fileId;

    const [topCells, setTopCells] = useState(null);
    const [phase, setPhase] = useState(0);
    const [parsedItems, setParsedItems] = useState(null);
    const [originalData, setOriginalData] = useState({original: [], processed: []});
    const [processedItems, setProcessedItems] = useState(null);
    const [stat, setStat] = useState<any>({});
    const [saveTrigger, setSaveTrigger] = useState(false);

    const [resultItem, setResultItem] = useState(null);
    const [resultChangeDialogState, setResultChangeDialogState] = useState(false);

    useEffect(() => {
            (async () => {
                if (phase === 0) {
                    const response = await axios.get(`/api/parser/status/${fileId}`);
                    if (response && response.data) {
                        if (response.data.phase === 0) {
                            setPhase(1);
                        } else {
                            setPhase(response.data.phase);
                        }
                    }
                } else if (phase === 1) {
                    const response = await axios.get(`/api/parser/topcells/${fileId}`);
                    if (response && response.data) {
                        setTopCells(response.data);
                    }
                } else if (phase === 2) {
                    const response = await axios.get(`/api/parser/table/${fileId}`);
                    if (response && response.data) {
                        setParsedItems(response.data);
                        setOriginalData({
                            ...originalData,
                            original: response.data
                        });
                        setStat({
                            total: response.data.filter((f: any) => f.isData).length
                        })
                        return setPhase(3);
                    }
                } else if (phase === 3) {
                    if (!parsedItems) {
                        return setPhase(2);
                    }
                    const response = await axios.get(`/api/parser/process/${fileId}`);
                    if (response && response.data) {
                        const {items, ...newStat} = response.data
                        setProcessedItems(items);
                        setOriginalData({
                            ...originalData,
                            processed: items
                        });
                        setStat({
                            ...stat,
                            ...newStat
                        })
                    }
                }
            })()
        },
        [fileId, phase],
    );

    const onSaveColumn = async (columns: any, baseRow: number) => {
        const response = await axios.post(`/api/parser/set-columns/${fileId}`, {
            columns,
            baseRow
        }).then(function () {
            setPhase(phase + 1);
        });
    }

    const updateProcessedItems = (indexes: number[], result: any) => {
        debugger;
        for (let i = 0; i < indexes.length; i++) {
            const rowIndex = indexes[i];
            const findIndex = originalData.original.findIndex((f: any) => f.rowIndex === rowIndex);

            originalData.processed[findIndex].selected = result;
        }
        // setProcessedItems(processedItems.slice());
        setOriginalData({
            ...originalData,
            processed: originalData.processed.slice()
        });
    }

    const updateProcessedItem = (rowIndex: number, result: any) => {
        const findIndex = parsedItems.findIndex((f: any) => f.rowIndex === rowIndex);
        if (findIndex > -1) {
            processedItems[findIndex].selected = result;
            setProcessedItems(processedItems.slice());
            setOriginalData({
                ...originalData,
                processed: processedItems.slice()
            });
        }
    }

    const onUpdateItemHandler = async (itemOriginal: any, itemResult: any, needSave: boolean) => {
        setResultChangeDialogState(false);
        const response = await axios.put(`/api/parser/result/${fileId}`, {
            index: itemOriginal.original.rowIndex,
            selected: itemResult,
            needSave
        })
        if (response && response.data) {
            const {items, ...newStat} = response.data
            setStat({
                ...stat,
                ...newStat
            })
            if (items) {
                updateProcessedItems(items, itemResult);
            }
        }
        debouncedChangeHandler(filter)

    }

    const [filter, setFilter] = useState('');
    const changeFilterHandler = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const value = event.target.value;
        setFilter(value);
        debouncedChangeHandler(value)
    }
    const changeFilter = (value: string) => {
        const parsed = [];
        const processed = [];
        for (let i = 0; i < originalData.original.length; i++) {
            const originalDatum = originalData.original[i];
            const processedDatum = originalData.processed[i];

            if(originalDatum.name.toLowerCase()
                .replace(/ґ/ig, 'г')
                .replace(/i/ig, 'і')
                .replace(/\s+/g, ' ').replace(/\n/g, ' ').replace(/  /g, ' ')
                .includes(
                    value.toLowerCase()
                    .replace(/\s+/g, ' ').replace(/\n/g, ' ').replace(/  /g, ' ')
                    .replace(/ґ/ig, 'г')
                    .replace(/i/ig, 'і'))
            ){
                parsed.push(originalDatum);
                processed.push(processedDatum);
            }
        }
        // const result = originalData.filter((f: any) => f.name.toLowerCase().includes(value.toLowerCase()));
        setParsedItems(parsed);
        setProcessedItems(processed);
    }

    const debouncedChangeHandler = useMemo(
        () => debounce(changeFilter, 300)
        , [originalData, processedItems]);

    const itemClickHandler = (itemOriginal: any, itemProcessed: any) => {
        debugger;
        setResultItem({original: itemOriginal, processed: itemProcessed});
        setResultChangeDialogState(true);
    }

    return (
        <>
            {resultItem && <ChangeResultDialog item={resultItem} onUpdateItem={onUpdateItemHandler} handleClose={() => setResultChangeDialogState(false)} openState={resultChangeDialogState}/>}
            <Box className={classes.header}>
                {phase === 2 && !parsedItems && <CircularProgress/>}
                {phase === 3 && !processedItems && <CircularProgress/>}
                {phase === 1 && <Button disableElevation variant="outlined" onClick={() => {
                    setSaveTrigger(true);
                    setTimeout(() => setSaveTrigger(false), 300)
                }}>Начать обработку</Button>}
                {phase === 3 && <Button disableElevation variant="outlined" onClick={() => {
                    window.open((process.env.REACT_APP_BACK_URL.length === 1?'':process.env.REACT_APP_BACK_URL)+'/api/parser/export/'+fileId);
                }}>Экспортировать</Button>}

                {phase === 3 &&
                    <TextField
                        className={classes.filterInput}
                        onChange={changeFilterHandler}
                        value={filter}
                        label="Фильтр"
                        type="text"
                        fullWidth
                    />}
            </Box>

            {phase === 3 && <Box>
              <Typography variant={'subtitle1'}>Статистика</Typography>
              <List component="nav" aria-label="main mailbox folders">
                <ListItem button>
                  <ListItemIcon>
                    <Badge badgeContent={stat.total} color="primary" max={10000}>
                      <Inbox/>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Всего позиций"/>
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <Badge badgeContent={stat.success} color="primary" max={10000}>
                      <Inbox/>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Автоматически подтверждено"/>
                </ListItem>
                <ListItem button>
                  <ListItemIcon>
                    <Badge badgeContent={stat.selected} color="primary" max={10000}>
                      <Inbox/>
                    </Badge>
                  </ListItemIcon>
                  <ListItemText primary="Обработано"/>
                </ListItem>

              </List>
            </Box>}
            {phase === 1 && topCells && <PrepareTable data={topCells} onSave={onSaveColumn} saveTrigger={saveTrigger}/>}
            {React.useMemo(
                () => {
                    return (
                        phase >= 2 && parsedItems && <ParseTable original={parsedItems} processed={processedItems} onItemClick={itemClickHandler}/>
                    )
                }, [processedItems, phase]
            )}
        </>
    );
};

export default ProcessPage;
