import React, {FunctionComponent} from 'react';
import {AppBar, Box, Grid, makeStyles, Paper, SvgIcon, Theme, Toolbar, Typography} from '@material-ui/core';
import {RouteComponentProps, useLocation} from 'react-router';
import { Link } from 'react-router-dom';
import cx from 'classnames';



const useStyles = makeStyles<Theme>(theme => ({
    link: {
        textDecoration: 'none',
        padding: '10px 10px',
        margin: "0 25px",
        fontSize: '16px',
        lineHeight: '19px'
    },
    activeLink: {
        backgroundColor: '#E0E0E0',
    },
    menuWrapper: {
    }
}));

interface LayoutProps  {
  children: React.ReactNode
};

const Layout: FunctionComponent<LayoutProps> = (props: LayoutProps) => {
  const classes = useStyles();

    const location = useLocation();

  return (
    <>
        {/*<Box className={classes.menuWrapper} display={"flex"} alignItems={"center"}>*/}
        {/*    <Link to="/file/upload" className={cx(classes.link, {*/}
        {/*        [classes.activeLink]: location.pathname === '/file/upload'*/}
        {/*    })}>Файл</Link>*/}
        {/*    <Link to="/documents" className={cx(classes.link, {*/}
        {/*        [classes.activeLink]: location.pathname === '/documents'*/}
        {/*    })}>Документы</Link>*/}
        {/*</Box>*/}

       {/* <Toolbar>
            <Link to="/file/upload" className={cx(classes.link, {
                [classes.activeLink]: location.pathname === '/file/upload'
            })}>Файл</Link>
            <Link to="/documents" className={cx(classes.link, {
                [classes.activeLink]: location.pathname === '/documents'
            })}>Документы</Link>
        </Toolbar>*/}
      {/*<AppBar position="static">*/}
      {/*    <Toolbar>*/}
      {/*        <Link to="/file/upload">Файл</Link>*/}
      {/*        <Link to="/documents">Документы</Link>*/}
      {/*    </Toolbar>*/}
        {/*<Toolbar>*/}
          {/*<Link to="/file">aa</Link>*/}
          {/*<Link to="/main">aa</Link>*/}
          {/*{this.props.project && 'Проект ' + this.props.project.name}*/}
          {/*{this.props.projectCompany && 'Компания ' + this.props.projectCompany.name}*/}
        {/*</Toolbar>*/}
      {/*</AppBar>*/}
      {props.children}
    </>
  );
};

export default Layout;
