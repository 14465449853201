import React, {FunctionComponent} from 'react';
import {Box, makeStyles, Theme, Typography} from '@material-ui/core';
import Layout from './components/layout/Layout';
import {Route, Switch} from 'react-router';
import StartPage from './pages/StartPage';
import ProcessPage from './pages/ProcessPage';
import ProjectsPage from './pages/ProjectsPage';


const useStyles = makeStyles<Theme>(theme => ({

}));

interface AppProps {

}

const App: FunctionComponent<AppProps> = (props: AppProps) => {
  const classes = useStyles();

  return (
    <Layout>
        <Switch>
            {/*<PrivateRoute isAuth={this.props.isAuth} redirectPath='/admin/login' exact path='/admin/' component={ProjectListPage}/>*/}
            <Route exact path='/file/upload' component={StartPage}/>
            <Route exact path='/process/:fileId' component={ProcessPage}/>
            <Route exact path='/documents' component={ProjectsPage}/>
        </Switch>
    </Layout>
  );
};

export default App;
